var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "모든 작업계획별 소요자재",
              columns: _vm.grid.columns,
              merge: _vm.grid.merge,
              data: _vm.grid.data,
              gridHeight: _vm.grid.height,
              editable: false,
              isTitle: true,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "grid2",
            attrs: {
              title: "모든 작업계획별 서비스(외주)",
              columns: _vm.grid2.columns,
              merge: _vm.grid2.merge,
              data: _vm.grid2.data,
              gridHeight: _vm.grid2.height,
              isTitle: true,
              editable: false,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }